'use strict';

angular.module('webPartnerPortalApp')
  .controller('PartnerCtrl', ['partner', 'labels', '$http', '$modal', '$window', 'channels','$scope', function (partner, labels, $http, $modal, $window, channels, $scope) {
    var PartnerCtrlVM = this;
    PartnerCtrlVM.partner = partner.data;
    PartnerCtrlVM.initialPartner = angular.copy(partner.data);
    PartnerCtrlVM.labels = labels.data;
    PartnerCtrlVM.verify = {};

    if(typeof partner.data.partner_mapping !== 'undefined'){
      PartnerCtrlVM.partner.partner_mapping = PartnerCtrlVM.partner.partner_mapping.map(function(obj){
        var rObj = {};
        rObj = obj;
        rObj.name = obj.campaign_program_name || obj.campaign_name || PartnerCtrlVM.name;
        return rObj;
      });
    }

    PartnerCtrlVM.openModal = function () {
      var modalInstance = $modal.open({
        templateUrl: 'newPartnerModal.html',
        controller: 'PartnerModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: 'modal',
        resolve: {
          partner: function(){
            return partner.data;
          },
          newPartner: function () {
            return false;
          }
        }
      });
      modalInstance.result.then(function() {
        console.log('load new partner data');
      }, function () {
        PartnerCtrlVM.partner = PartnerCtrlVM.initialPartner;
        console.log('error');
      });
    };

    PartnerCtrlVM.verify = function (credentials) {
      $window.open(PartnerCtrlVM.partner.verify_base_url + '/?refac=' + credentials.ref_ac + '&refmc=' + credentials.ref_mc, '_blank');
      $http.get('api/partners/verify?ref_ac=' + credentials.ref_ac + '&ref_mc=' + credentials.ref_mc)
        .success(function () {
          PartnerCtrlVM.verify[credentials.ref_ac] = true;
        })
        .error(function(e){
          $scope.error = e;
        });
    };

    PartnerCtrlVM.openCampaignCreationModal = function (credentials) {
      var modalInstance = $modal.open({
        templateUrl: 'newCampaignModal.html',
        controller: 'PartnerNewCampaignModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: 'modal',
        size: 'sm',
        resolve: {
          credentials: function(){
            // add campaign src id to credentials object
            return angular.extend({campaign_source_id: partner.data.campaign_source_id}, credentials);
          },
          channels: function(){
            return channels.data;
          }
        }
      });
      modalInstance.result.then(function() {
        $http.get('/api/partners/id/' + PartnerCtrlVM.partner.campaign_source_id + '?field_group=CB')
        .success(function(res){
          PartnerCtrlVM.partner = res;
        });
      }, function () {
        console.log('error');
      });
    };
    PartnerCtrlVM.generateOauthCredentials = function(upsert){
      $http.post('/api/partners/oauth', {campaign_source_id : PartnerCtrlVM.partner.campaign_source_id, is_upsert : upsert})
        .success(function (response) {
          PartnerCtrlVM.showOAuthePanel = false;
          if(response.client_info){
            if(response.client_info !== '' && response.campaign_program !== null){
              PartnerCtrlVM.showOAuthePanel = true;
              PartnerCtrlVM.clientiId = response.client_info.client_info.oauth_client_id;
              PartnerCtrlVM.clientSecret = response.client_info.client_info.oauth_client_secret;
              PartnerCtrlVM.partnerSourceCode = response.campaign_program.partner_source_code;
            }
        }
        }).error(function(){

        });
    };
    PartnerCtrlVM.generateOauthCredentials(false);

  }])
  .controller('PartnersCtrl', ['$scope', 'partners', '$location', '$modal', '$http', 'loader', function($scope, partners, $location, $modal, $http, loader) {
    var PartnersCtrlVM = this,
      searchInitialized;
    loader.close();
    PartnersCtrlVM.partners = partners.data.result;

    function setPaginationValues (partners, offset) {
      PartnersCtrlVM.offset = offset || 0;
      PartnersCtrlVM.resultCount = partners.result_count;
      PartnersCtrlVM.totalCount = partners.total_count;
    }
    setPaginationValues(partners.data);

    PartnersCtrlVM.fetchPartners = function (direction) {
      var offset = direction === 'FWD' ? PartnersCtrlVM.offset + 100 : PartnersCtrlVM.offset - 100;
      if (direction === 'FWD') {
        if (PartnersCtrlVM.offset + PartnersCtrlVM.resultCount === PartnersCtrlVM.totalCount){
          return;
        } 
      } else {
        if (PartnersCtrlVM.offset === 0){
          return;
        }
      }
      $http.get('/api/partners?limit=100&offset=' + offset)
        .success(function (partners) {
          setPaginationValues(partners, offset);
          PartnersCtrlVM.partners = partners.result;
        });
    };

    PartnersCtrlVM.searchText = '';
    // Search Options and Functionality
    PartnersCtrlVM.searchOptions = {
      debounce: { 'default': 300}
    };
    // search is debounced by model options above, therefore onchange should call API directly
    $scope.$watch('PartnersCtrlVM.searchText', function(n, o) {
      if (n && n !== o) {
        searchInitialized = true;
        if (n.length > 2) {
          // get filtered list of partners
          $http.get('/api/partners?search_string=' + encodeURI(n))
            .success(function (partners) {
              setPaginationValues(partners);
            //  PartnersCtrlVM.partners = partners.result;
                var partnersMap = [];
                var index = 0;
                var ref_index = 0;
                angular.forEach(partners.result, function(partnerInfo) {
                  partnersMap[index] = {};
                  partnersMap[index].campaign_source_id =  partnerInfo.campaign_source_id;
                  partnersMap[index].contact_email =  partnerInfo.contact_email;
                  partnersMap[index].contact_name =  partnerInfo.contact_name;
                  partnersMap[index].source_name =  partnerInfo.source_name;

                  if(partnerInfo.campaigns){
                      partnersMap[index]['refs']= {};
                    angular.forEach(partnerInfo.campaigns, function(campaign) {

                      partnersMap[index]['refs'][ref_index] = {};

                      //if(campaign.ref_ac.indexOf(PartnersCtrlVM.searchText) > -1){
                        partnersMap[index]['refs'][ref_index]['ref_ac'] =  campaign.ref_ac;
                    //  }
                      partnersMap[index]['refs'][ref_index]['ref_mc'] = [];
                      angular.forEach(campaign.programs, function(program) {
                        if(program.ref_mc !== null){
                          if(program.ref_mc.toLowerCase().indexOf(PartnersCtrlVM.searchText.toLowerCase()) > -1){
                              partnersMap[index]['refs'][ref_index]['ref_mc'].push(program.ref_mc);
                          }
                        }
                        return;
                      });
                      ref_index++;
                      return;
                    });

                  }
                  index++;
                  return partnersMap;
                });

              PartnersCtrlVM.partners = partnersMap;
            });
        }
      } else if (searchInitialized) {
        // get all partners
        $http.get('/api/partners')
          .success(function (partners) {
            console.log(n);
            setPaginationValues(partners);
            PartnersCtrlVM.partners = partners.result;
          });
      }
    });
    // Open Modal Method
    PartnersCtrlVM.openModal = function () {
      var modalInstance = $modal.open({
        templateUrl: 'newPartnerModal.html',
        controller: 'PartnerModalCtrl',
        controllerAs: 'vm',
        windowClass: 'modal',
        backdrop: 'static',
        resolve: {
          partner: function(){
            return {};
          },
          newPartner: function (){
            return true;
          }
        }
      });
      modalInstance.result.then(function() {
        angular.noop();
      }, function () {
        console.log('error');
      });
    };

    PartnersCtrlVM.viewPartner = function (id) {
      $location.path('/partner/' + id);
    };
  }])
  .filter('formatDate', function(){
    return function(input){
      if(input === null){ return ''; }
      if(input === 'N/A'){ return 'N/A'; }
      var _date;
      var inputDateFormat = 'YYYY-MM-DD HH:mm:ss +HHmm';
      _date = moment(input, inputDateFormat).format('MM/DD/YYYY');
      return _date;
    };
  })
  .filter('formatName', function(){
    return function(input){
      if(input === null){ return ''; }
      if(input === 'N/A'){ return 'N/A'; }
      return input.replace(/([a-z])([A-Z])/g, '$1 $2');
    };
  });
